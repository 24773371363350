import React from 'react';

function CapeWrath() {
    return (
        <>
            <h1>The Cape Wrath Ultra</h1>

            <img src="assets/CapeWrath/CapeWrath.jpg" alt="Cape Wrath Ultra" />

            <div className="spacer"></div>
        </>
    );
};

export default CapeWrath;
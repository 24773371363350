const BoardMembers = [
  {
    id: 0,
    img: "assets/Board/AndrewParrott.jpg",
    name: "Cllr. Andrew Parrott",
    firstName: "Andrew",
    title: "",
    biography: [
      "Born in London, Andrew graduated from Aberdeen University before starting a career in the British Army.",
      "As an officer in the Royal Corps of Transport and later the Royal Logistic Corps Andrew spent much of his career abroad, serving with the Brigade of Gurkhas in Hong Kong, the British Army of the Rhine and the Belize Garrison. Andrew saw operational service in Northern Ireland and the Gulf. In Scotland he served with the Territorial Army as Adjutant of the Lowland Transport Regiment in Glasgow and later as Commanding Officer of the Scottish Transport Regiment in Dunfermline.",
      "From 1999 to 2003 Andrew served at the Baltic Defence College in Estonia assisting with the training of officers for the armed forces of the newly independent Baltic States, Estonia, Latvia and Lithuania.",
      "In his final appointment in the Army Andrew headed up the movements planning team within the armed forces staff responsible for global movements and logistics.",
      "After 36 years of service he retired from the Army in summer 2012 and now lives in Perth with his wife, having owned a property in the city since 1999.",
      "Andrew joined the SNP just prior to the 2011 Scottish Election and was first elected to represent the Perth City Centre Ward in May 2015. He was re- elected for the same ward in May 2017 and May 2022 and was elected Depute Provost of Perth and Kinross on 25 May 2022.",
      "Andrew's other interests include military history and railway history.",
    ],
  },
  {
    id: 1,
    img: "assets/Board/PaulCrawford.jpg",
    name: "Dr. Paul Crawford",
    firstName: "Paul",
    title: "",
    biography: [
      "Born in Aberdeen, Paul graduated from the University of Dundee and continued on an academic and engineering career.",
      "His original degree was a 1st class honours in Electronics and Electrical engineering, followed by a PhD in metrological satellite ground station technology while working at the Dundee Satellite Reception Station (DSRS) at the university. During this period he taught radio engineering and other aspects of electronics to both undergraduate and masters degrees.",
      "Later he formed a consultancy company to do work for various organisation in the related areas, such as Eumetsat and the European Space Agency, along with various commercial organisations.",
      "When the DSRS was closed following the withdrawal of government funding in 2019 he partnered with another former employee of DSRS to form Dundee Satellite Station Ltd. to carry on similar work on a commercial basis and the salvaged DSRS equipment was set up again at the former RAF Errol airfield between Perth and Dundee.",
      "Hobbies and interests include art and hill walking.",
    ],
  },
  {
    id: 2,
    img: "assets/Board/RobJarvis.jpg",
    name: "Dr. Rob Jarvis",
    firstName: "Rob",
    title: "",
    biography: [
      "Rob is a medical doctor and has worked in general practice in Tayside and Fife and at Rachel House children's hospice.",
      "More recently, Rob has worked full time for Dundee University teaching medical students where he's overseen student support in the medical school.",
      "He has interests in wellbeing, fairness and global health and he's had fun collecting degrees in human sciences and public health in addition to medicine. He maintains a healthy interest in international politics, economics, and ethics.",
      "He was fortunate to have a parent who worked for British Airways and as such saw a good part of the world in his youth. He's spent short but formative episodes in hospital and community healthcare in sub-Saharan Africa and has worked in Maori Mental Health in New Zealand.",
      "He enjoys the opportunities that Perth supplies for outdoor activities. He's up on his feet or cycling pretty much every day. Say hello if you see him on the top of Kinnoull Hill.",
      "Rob lives in Perth with his wife Laura and two kids, Em and Fin.",
    ],
  },
];

export default BoardMembers;

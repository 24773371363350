import React from "react";
import "../scss/QR.scss";

function QR() {
  return (
    <div>
      <h1>Cape Wrath Ultra</h1>

      <img
        className="capeWrathImg"
        src="assets/CapeWrath/frame.png"
        alt="capeWrath"
      />
    </div>
  );
}

export default QR;
